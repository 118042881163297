<template>
  <div>
    <el-card>
      <el-row :gutter="20">
        <!-- <el-col :span="7">
          <el-input placeholder="别名">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>-->
        <el-col :span="7">
          <span class="demonstration">借走状态: &nbsp; &nbsp; &nbsp; &nbsp;</span>
          <el-select v-model="queryParam.borrowStatus" placeholder="请选择">
            <el-option
              v-for="item in optionss"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button type="primay" @click="getBankBorrowButton()">查询</el-button>
          <el-button type="primay" @click="resettingButton">重置</el-button>
        </el-col>
      </el-row>
      <!-- 按钮区 -->
      <el-row :gutter="20" style="margin:9px 2px">
        <!-- <el-button type="success" icon="el-icon-add" size="min" @click="addBorrowButton()">新增支出</el-button>-->
        <el-button type="danger" icon="el-icon-edit" size="min" @click="EditBorrowFromButton()">编辑</el-button>
        <el-button type="success" icon="el-icon-edit" size="min" @click="ReturnBorrowButton()">归还</el-button>
        <!-- <el-button   type="success"  icon="el-icon-edit"  size="min"  @click="CancelBorrowFromButton()"  >补录</el-button>-->
        <el-button type="success" icon="el-icon-edit" size="min" @click="ClearBorrowButton()">作废</el-button>
        <!-- <el-button type="danger">删除</el-button>
        <el-button type="danger">查看详情</el-button>-->
      </el-row>
      <!-- 列表区域 -->
      <el-table :data="bankBorrowList" border stripe>
        <el-table-column label="序号" align="center" width="65">
          <template slot-scope="scope">
            <el-radio
              :label="scope.$index"
              v-model="radio"
              @change.native="getCurrentRow(scope.row)"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column label="支行名称" prop="subbranch" />
        <el-table-column label="借走人的别名" prop="borrowAlias" />
        <el-table-column label="借走日期" prop="borrowData" />
        <el-table-column label="借走备注" prop="borrowRemark" />
        <el-table-column label="预计归还时间" prop="preReturnData" />
        <el-table-column label="还给结束时间" prop="returnData" />
        <el-table-column label="借走资金" prop="borrowMoney" />
        <el-table-column label="以还款金额" prop="returnMoney" />
        <el-table-column label="还未归还" prop="noReturnMoney" />
        <el-table-column label="借走状态" prop="borrowStatus" />
        <el-table-column label="差异金额" prop="differMoney" />
        <el-table-column label="是否一定归还" prop="isMustBack" />
        <el-table-column label="是否作废" prop="isCancel" />
      </el-table>
    </el-card>
    <AddBorrrow ref="AddBorrrowFrom"></AddBorrrow>
    <CancelBorrow ref="CancelBorrowFrom"></CancelBorrow>
    <ReturnBorrow ref="ReturnBorrowFrom"></ReturnBorrow>
  </div>
</template>
<script>
import { borrowList, cancellMoney } from "@/api/capital/bank/bankBorrow";
import AddBorrrow from "./Borrow/AddBorrrow";
import CancelBorrow from "./Borrow/CancelBorrow";
import ReturnBorrow from "./Borrow/ReturnBorrow";

export default {
  name: "bankBorrow",
  components: {
    AddBorrrow,
    CancelBorrow,
    ReturnBorrow
  },
  data() {
    return {
      //默认第一个选项卡
      activeName: "first",
      bankBorrowList: [],
      id: null,
      radio: null,
      borrows: null,
      borrowAliass: null,
      borrowStatuss: null,
      queryParam: {
        id: null,
        borrowStatus: null
      },
      optionss: [
        {
          value: "1",
          label: "未知"
        },
        {
          value: "2",
          label: "借用中"
        },
        {
          value: "3",
          label: "正常结束"
        },
        {
          value: "4",
          label: "作废"
        },
        {
          value: "5",
          label: "补录正常结束的"
        },
        {
          value: "6",
          label: "正常结束但是金额异常的"
        },
        {
          value: "7",
          label: "补录结束金额异常"
        }
      ]
    };
  },
  mounted() {
    // this.getBankBorrowButton();
  },
  methods: {
    getBankBorrowButton() {
      borrowList(this.queryParam).then(res => {
        console.log(res);
        this.bankBorrowList = res.result;
      });
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.id = row.id;
      this.borrows = row.borrowMoney;
      this.borrowAliass = row.borrowAlias;
      this.bankType = row.bankType;
      this.templateSelection = row;
      this.borrowStatuss = row.borrowStatus;
    },
    addBorrowButton() {
      console.log(this.id);
      this.$refs.AddBorrrowFrom.show(this.id, 1);
    },
    EditBorrowFromButton() {
      if ((this.id === null) | (this.id === undefined))
        return this.$message.error("请选择一条数据");
      this.$refs.AddBorrrowFrom.show(this.id, 2);
    },
    ReturnBorrowButton() {
      if ((this.id === null) | (this.id === undefined))
        return this.$message.error("请选择一条数据");
      if (this.borrowStatuss != 2)
        return this.$message.error("只能选择进行中的归还");
      this.$refs.ReturnBorrowFrom.show(this.id, 1);
      this.$refs.ReturnBorrowFrom.title = "归还金额";
    },
    CancelBorrowFromButton() {
      if ((this.id === null) | (this.id === undefined))
        return this.$message.error("请选择一条数据");
      if (this.borrowStatuss != 4)
        return this.$message.error("不是作废的不能补录");
      this.$refs.ReturnBorrowFrom.show(this.id, 2);
      this.$refs.ReturnBorrowFrom.title = "补录金额";
    },
    ClearBorrowButton() {
      if (
        (this.id === null) |
        (this.id === undefined) |
        (this.borrowStatuss != 2)
      )
        return this.$message.error("请选择一条数据");
      this.$confirm(
        this.borrowAliass + "借走的" + this.borrows + "是否作废？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          cancellMoney(this.id).then(res => {
            this.getBankBorrowButton();
            return this.$message.success(res.message);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    resettingButton() {
      this.queryParam = {};
      this.getBankBorrowButton();
    }
  }
};
</script>
